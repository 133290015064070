.language-selector {
  display: flex;
  gap: 8px
}


@media (max-width: 600px)  {
  .language-selector {
    display: flex;
    gap: 20px
  }
}