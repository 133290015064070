.navbar {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 30px 80px;

  position: fixed;
  width: calc(100% - 160px);
  z-index: 2;
}

.navbar__logo {
  width: fit-content;
}

.navbar__logo a {
  width: fit-content;
  display: block;
}

.navbar__menu {
  align-items: center;
  display: flex;
  gap: 20px;
}

.navbar__menu a {
  text-decoration: none; 
  color: inherit;
  font-size: 0.875rem;
}

.burger-menu-mobile {
  display: none;
}

.navbar__mobile {
  background-color: #fff;
  position: fixed;
  top: 80px;
  left: 100%;
  width: 100%;
  display: none; 
  transform: translateX(100%); 
  transition: transform 0.5s ease-in-out;
  padding: 60px;
}

.navbar__mobile__container {
  display: grid;
  row-gap: 30px;
}

.navbar__mobile__container a {
  text-decoration: none; 
  color: inherit;
  font-size: 16px;
  width: fit-content;
}

.navbar__mobile.open {
  left: 0; 
  transform: translateX(0);
}

.navbar__mobile.close {
  transform: translateX(100%);
  left: 100%;
  transition: transform 0.5s ease-in-out, left 0.5s ease-in-out;
}

@media (max-width: 1180px)  {
  .navbar {
    padding: 30px 60px;
    width: calc(100% - 120px);
  }

  .navbar__logo svg {
    height: 50px;
    width: fit-content;
  }
}

@media (max-width: 1000px) {
  .navbar__menu, .navbar__language-selector-laptop {
    display: none;
  }

  .burger-menu-mobile, .navbar__mobile {
    display: block;
  }
}

@media (max-width: 600px)  {
  .navbar {
    padding: 20px 40px;
    width: calc(100% - 80px);
  }

  .navbar__mobile {
    padding:  30px 40px
  }

  .navbar__logo{
    width: 150px;
  }

  .navbar__logo svg{
    width: 100%;
  }
  .navbar__logo a{
    width: 100%;
    height: 50px;
  }
}