.prev-next-button {
  position: absolute;
  top: 53%;
  color: #fff;
  z-index: 2;
}

.prev-next-button svg {
  height: 35px;
  width: 30px;
}

.prev-next-button--right {
  right: 15px;
}

.prev-next-button--left {
  left: 15px;
  transform: rotate(180deg)
}

@media (max-width: 1180px)  {
  .prev-next-button svg {
    height: 30px;
    width: 25px;
  }

  .prev-next-button--right {
    right: 10px;
  }
  
  .prev-next-button--left {
    left: 10px;
  }
}

@media (max-width: 1000px)  {
  .prev-next-button {
    display: none;
  }
}