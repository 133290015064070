.home {
  width: 100%;
  height: calc(100vh - 180.5px);
  padding-top: 139.5px;
}

.home__image{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.home__image--mobile {
  display: none;
}

.home__title {
  position: absolute;
  color: #fff;
  top: 70%;
  left: 80px;
  font-size: 2.2rem;
}

@media (max-width: 1300px) {
  .home__title {
    top: 70%;
    left: 80px;
    font-size: 1.8rem;
  }
}

@media (max-width: 1180px) {
  .home {
    height: calc(100vh - 155.5px);
    padding-top: 114.5px;
  }

  .home__title {
    font-size: 1.5rem;
    left: 60px;
    top: 68%;
  }
}

@media (max-width: 800px) {
  .home__title {
    font-size: 1.2rem;
    left: 30px;
    top: 68%;
  }
}

@media (max-width: 600px) {
  .home {
    height: fit-content;
    padding-top: 90px;
    background-color: #24408F;
  }

  .home__image{
    object-fit: cover;
    width: 100%;
    height: 450px;
    display: none;
  }

  .home__image--mobile {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 500px;

  }

  .home__title {
    top: 330px;
    font-size: 1.3rem;
    padding: 10px;
    width: calc(100% - 20px);
    text-align: center;
    left: 0;
  }
}