.areas-of-intervention {
  min-height: calc(100vh - 180.5px);
  color: #fff;
  background-color: #24408F;
  padding-top: 139.5px;
}

.areas-of-intervention__container {
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.areas-of-intervention__texts{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
}

.areas-of-intervention__texts p{
  line-height: 24px;
}

.areas-of-intervention__texts div { 
  display: grid;
  row-gap: 20px;
  height: fit-content;
}

@media (max-width: 1180px)  {
  .areas-of-intervention {
    min-height: calc(100vh - 155.5px);
    padding-top: 114.5px;
  }

  .areas-of-intervention__container {
    padding: 40px 60px;
    gap: 60px
  }

  .areas-of-intervention__texts{
    gap: 70px;
  }
}

@media (max-width: 1000px)  {
  .areas-of-intervention__container {
    gap: 40px
  }

  .areas-of-intervention__texts{
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

@media (max-width: 600px)  {
  .areas-of-intervention {
    min-height: 0px;
    padding-top: 0px;
  }

  .areas-of-intervention__container {
    padding: 0 20px;
    margin-top: -80px;
  }
}