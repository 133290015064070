@import '../components/index.css';
@import './Home/Home.css';
@import './AreasOfIntervention/AreasOfIntervention.css';
@import './Contact/Contact';
@import './Fees/Fees.css';
@import './Team/Team.css';
@import './Mobile/Mobile.css';

* {
  margin: 0;
  padding: 0;
  font-family: 'Gotham';
}

body {
  background-color: #fff;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  outline: none;
  cursor: pointer;
}

.App {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}

.laptop-layout {
  display: block;
}

.mobile-layout {
  display: none;
}

.title {
  font-family: "Baskerville"
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Baskerville';
  src: url('../assets/fonts/LibreBaskerville-Regular.ttf') format('truetype');
}

@media (max-width: 1180px)  {
  p {
    font-size: 16px;
  }
}

@media (min-width: 1600px)  {
  .prev-next-button svg{
    height: 50px;
    width: 45px;
  }

  .home__title {
    font-size: 45px;
  }

  h2 {
    font-size: 28px;
  }

  .language-selector {
    gap: 16px
  }

  .language-selector button svg {
    width: 30px;
    height: 30px;
  }

  p, .navbar__menu a {
    font-size: 20px;
  }

  p {
    line-height: 32px !important;
  }

  .contact__little-container__bottom img {
    height: 350px;
  }
}

@media (max-width: 600px)  {
  .laptop-layout {
    display: none;
  }

  .mobile-layout {
    display: block;
  }
}