footer {
  color: #656262;
  background-color: #fff;
  font-size: 0.75rem;
  padding: 12px 20px;
  line-height: 18px;
}

footer a {
  color: inherit;
}

@media (max-width: 600px)  {
  footer {
    font-size: 0.6rem;
    padding: 12px 30px;
    align-items: center;
  }
}