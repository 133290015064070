.burger-menu {
  cursor: pointer;
  height: 22px;
  width: 22px;
  border: 1px solid transparent;
  display: none;
}

.burger-menu__open {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.burger-menu__open div {
  height: 2px;
  border-radius: 4px;
  background-color: #000;
}

.burger-menu__close div {
  height: 2px;
  border-radius: 4px;
  background-color: #000;
}

.burger-menu__close :nth-child(1) {
  transform: rotate(45deg);
  margin: 10px -5px;
  width: 32px;
}

.burger-menu__close :nth-child(2) {
  transform: rotate(-45deg);
  margin: -12px -5px;
  width: 32px;
}

@media (max-width: 1000px) {
  .burger-menu  {
    display: block;
  }
}