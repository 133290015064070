.contact {
  min-height: calc(100vh - 180.5px);
  color: #fff;
  background-color: #24408F;
  padding-top: 139.5px
}

.contact__principal-container {
  display: flex;
  padding: 40px 80px;
}

.contact__principal-container__title {
  margin-bottom: 0px !important;
}

.contact__principal-container h2 {
  margin-bottom: 70px;
}


.contact__little-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.contact__little-container__bottom {
  height: calc(100% - 110px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.contact__little-container__bottom div {
  display: grid;
  row-gap: 20px;
  line-height: 24px;
}

.contact__little-container img {
  height: 200px;
  object-fit: contain;
  object-position: left;
  margin-top: 20px
}

.mail {
  display: flex;
  gap: 4px;
}

.mail a, .contact__little-container__bottom p a {
  color: inherit;
}

@media (max-width: 1180px)  {
  .contact {
    min-height: calc(100vh - 155.5px);
    padding-top: 114.5px;
  }

  .contact__principal-container {
    padding: 40px 60px;
    gap: 60px
  }

  .contact__principal-container h2 {
    margin-bottom: 60px;
  }
}

@media (max-width: 1000px)  {
  .contact__principal-container {
    flex-direction: column;
    gap: 80px
  }

  .contact__little-container {
    width: 100%;
  }

  .contact__principal-container h2 {
    margin-bottom: 40px;
  }
}

@media (max-width: 600px)  {
  .contact {
    min-height: 0;
    padding-top: 0px;
  }

  .contact__principal-container {
    padding: 0 20px ;
    gap: 40px;
  }

  .contact__principal-container h2 {
    margin-bottom: 20px;
  }

  .mail {
    flex-wrap: wrap;
  }
}