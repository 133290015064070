.team {
  min-height: calc(100vh - 180.5px);
  color: #fff;
  background-color: #24408F;
  padding-top: 139.5px;
}

.team__container {
  display: flex;
  gap: 100px;
  padding: 40px 80px;
  height: calc(100% - 80px);
}

.team__container h2 {
  margin-bottom: 70px;
}

.team__container p {
  line-height: 24px;
}

.team__container img {
  height: 200px;
}

.team__container__right div {
  display: flex;
  gap: 20px
}

.team__container__center {
  width: 60%;
}

.team__container__center div{
  display: flex;
  flex-direction: column;
  gap: 20px
}

@media (max-width: 1265px)  {
  .team__container {
    gap: 60px
  }

  .team__container img {
    height: 150px
  }
}

@media (max-width: 1180px)  {
  .team {
    min-height: calc(100vh - 155.5px);
    padding-top: 114.5px;
  }

  .team__container {
    padding: 40px 40px;
  }

  .team__container h2 {
    margin-bottom: 60px;
  }
}

@media (max-width: 1000px)  {
  .team__container {
    display: flex;
    flex-direction: column;
    gap: 80px;
  }

  .team__container h2 {
    margin-bottom: 0px;
  }

  .team__container h3{
    margin-bottom: 20px;
  }

  .team__container img {
    height: 170px;
    margin-bottom: 20px;
  }

  .team__container__center {
    width: 100%;
  }
}

@media (max-width: 600px)  {
  .team {
    padding-top: 0px;
  }

  .team__container {
    padding: 0 20px ;
    gap: 40px;
  }
}