.fees {
  min-height: calc(100vh - 180.5px);
  color: #fff;
  background-color: #24408F;
  padding-top: 139.5px;
}

.fees__container {
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.fees__text {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
}

.fees__text p {
  line-height: 24px;
}

.fees__text div { 
  display: grid;
  row-gap: 20px;
  height: fit-content;
}

@media (max-width: 1180px)  {
  .fees {
    padding-top: 114.5px;
    min-height: calc(100vh - 155.5px);
  }

  .fees__container {
    padding: 40px 60px;
    gap: 60px;
  }

  .fees__text{
    gap: 70px;
  }
}

@media (max-width: 1000px) {
  .fees__container {
    gap: 40px;
  }

  .fees__text {
    display: block;
    gap: 30px;
  }
}

@media (max-width: 600px)  {
  .fees {
    min-height: 0;
    padding-top: 0px;
  }

  .fees__container {
    padding: 0 20px;
    gap: 40px;
  }
}